body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiListSubheader-root {
  color:#11268E !important;
  font-size: 11px !important;
  font-weight: bold !important;
  border-bottom:1px solid #EDEDED !important;
}

.MuiListItem-root.Mui-selected {
  background-color: #F2F6FA !important;
  box-shadow: 8px 1px 10px 0px rgba(117,116,116,0.15) inset;
}

.MuiListItem-root {
  border-bottom:1px solid #EDEDED !important;
}

.MuiListItemText-primary {
  font-weight: 700 !important;
  font-size: 14px !important;
  color: #3C3C3C !important;
}

.MuiListItemText-secondary {
  color: #707070 !important;
  font-size: 13px !important;
}